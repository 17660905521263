<template>
  <div class="mainPage">
    <div class="mainPageContent">
      <div class="contentPanel">
        <div class="headerLogo">
          <img src="../../assets/logo_b.png" alt="Osmose" />
        </div>

        <div class="formList">
          <div class="formAbsAuto">
            <div class="formGroup">
              <label>Email Address <span>*</span></label>
              <input
                type="email"
                class="formControl"
                required
                v-model="email"
                @blur="validateEmail()"
              />
              <div v-if="!validEmail" class="error">
                {{ emailErr }}
              </div>
            </div>
            <div class="formGroup">
              <label>License Key <span>*</span></label>
              <input
                type="text"
                class="formControl"
                required
                v-model="submittedLicKey"
              />
            </div>
            <div v-if="licKeyErr" class="error">
              {{ licKeyErr }}
            </div>
            <button
              :class="[
                validEmail && onLine ? 'btnblue' : 'btndef',
                'txtUppercase',
                'mb-2',
              ]"
              @click="onSubmit()"
            >
              SUBMIT</button
            ><br />
            <div class="textCenter">
              <a
                href="javascript: void(0);"
                class="backLink txtUppercase"
                @click="backPrompt()"
              >
                CANCEL
              </a>
            </div>
          </div>
        </div>
      </div>
      <sc-loader v-show="isLoading"></sc-loader>
      <div v-show="!onLine" class="internetOffline">
        <div class="offlineMsg">
          <img src="../../assets/disconnect.png" alt="disconnect" />
          <div class="ml-1">
            You are currently offline. please check your internet connection
          </div>
        </div>
      </div>

      <div class="bottomFixed">
        <ul class="listInline">
          <li class="listItem">
            <a href="javascript: void(0);" @click="onSupportClick()">
              SUPPORT
            </a>
          </li>
        </ul>
      </div>

      <div class="popUp" v-if="popup">
        <div class="popUpBox">
          <p class="popupText">{{ popupMsg }}</p>
          <div class="modalFooter">
            <button
              v-if="!registerFlag"
              class="btnRounded"
              v-on:click="closePrompt()"
            >
              CANCEL
            </button>
            &nbsp;&nbsp;
            <button class="btnRoundedFull" v-on:click="confirmPrompt()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LicenseAPI from "@/api/LicenseAPI";
import Loader from "../Loader.vue";
import { store } from "../../store/index.js";
import { v5 as uuidv5 } from "uuid";
import { Device } from "@capacitor/device";

export default {
  name: "emailVerification",

  data: () => ({
    email: "",
    validEmail: false,
    emailErr: "",
    onLine: navigator.onLine,
    popup: false,
    popupMsg: "Entered data will be lost. Continue?",
    submittedLicKey: "",
    licKeyErr: null,
    deviceID: "",
    isLoading: false,
    model: "",
    registerFlag: false,
  }),

  components: {
    "sc-loader": Loader,
  },

  created() {
    this.deviceID = localStorage.getItem("deviceID");
    this.model = localStorage.getItem("model");
  },

  watch: {
    email: function () {
      this.validateEmail();
    },
  },

    methods: {
        validateEmail() {
            if (
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())
            ) {
                this.validEmail = true;
            } else {
                this.validEmail = false;
                this.emailErr = "Please enter a valid email address!";
            }
        },

        onSubmit() {
            this.validateLicense();
        },

        validateLicense() {
            this.isLoading = true;
            this.licKeyErr = "";

            // // if (this.submittedLicKey.trim().toLowerCase() == "register")
            // //   this.registerFlag = true;
            // if (store.getters.fromFulcrum) {
            //     if (!this.deviceID) {
            if (this.submittedLicKey.trim().toLowerCase() == "register")
                this.registerFlag = true;
            if (this.registerFlag || !this.deviceID) {
                                    Device.getInfo().then(
                        (info) => {
                            let deviceinfo = info;
                            let infoText = `${deviceinfo.manufacturer}${deviceinfo.model}${deviceinfo.operatingSystem}${deviceinfo.osVersion}${deviceinfo.platform}${this.email}`;
                            console.log(infoText);
                            this.deviceID = uuidv5(infoText, uuidv5.URL);
                            console.log(this.deviceID);
                            localStorage.setItem("deviceID", this.deviceID);
            //             },
            //             (e) => console.log("there was an error", e)
            //         );
            //     }
            //     this.checkForFulcrumLicense();

            // } else {
                 this.validateLicenseAndAddDeviceMatrix();
            // }
        },
            (e) => console.log("there was an error", e));


        } else {
            this.validateLicenseAndAddDeviceMatrix();
        }
    },

        checkForFulcrumLicense() {
        // debugger;

            if (this.submittedLicKey.trim().toLowerCase() == "register" && store.getters.fromFulcrum) {
                this.registerFlag = true;
               
                LicenseAPI.getFulcrumLicense(
                    this.submittedLicKey.trim(),
                    this.email.trim(),
                    this.deviceID,
                    this.model
                ).then(
                    (response) => {
                        if (response && response.data.resultStatus == "Success") {
                            if (
                                response.data.messageCode == 107 ||
                                response.data.messageCode == 108
                            ) {
                                // debugger;
                                this.submittedLicKey = response.data.message;
                                localStorage.setItem("licensed", true);
                                localStorage.setItem("lastConnectTime", new Date());
                                localStorage.setItem("lastCheck", new Date());
                                localStorage.setItem("emailID", this.email.trim());
                                localStorage.setItem("licenseKey", this.submittedLicKey.trim());
                                store.dispatch("setIsAuthenticated", true);
                            }
                        }
                    }
                );


            } else {
                this.validateLicenseAndAddDeviceMatrix();
            }
        },

    
      validateLicenseAndAddDeviceMatrix() {
          // debugger;
          LicenseAPI.validateLicense(
              this.submittedLicKey.trim(),
              this.email.trim(),
              this.deviceID,
              this.model
          )
              .then((response) => {
                  this.addDeviceMatrix(
                      response.data.resultStatus,
                      response.data.message,
                      response.data.customerName
                  );
                  this.isLoading = false;

                  if (response && response.data.resultStatus == "Success") {
                      if (
                          response.data.messageCode == 107 ||
                          response.data.messageCode == 108
                      ) {

                          this.popupMsg = response.data.message;
                          this.popup = true;
                          this.submittedLicKey = "";
                      } else {
                          localStorage.setItem("licensed", true);
                          localStorage.setItem("lastConnectTime", new Date());
                          localStorage.setItem("lastCheck", new Date());
                          localStorage.setItem("emailID", this.email.trim());
                          localStorage.setItem("licenseKey", this.submittedLicKey.trim());
                          store.dispatch("setIsAuthenticated", true);
                          if (store.getters.fromFulcrum)
                              this.$router.replace("/evaluation");
                          else
                              this.$router.replace("/dashboard");

                      }
                  } else if (response && response.data.resultStatus == "Failure") {
                      store.dispatch("setIsAuthenticated", false);
                      this.licKeyErr = response.data.message;
                  }
              })
              .catch((error) => {
                  this.addDeviceMatrix("Failure", error);
                  console.log(error);
                  this.isLoading = false;
              });

      },

    addDeviceMatrix(status, msg, name = null) {
      let payload = {
        emailId: this.email.trim(),
        licenseKey: this.submittedLicKey.trim(),
        deviceId: this.deviceID,
        customerName: name & (name !== "") ? name : null,
        lastConnectDateTime: new Date(),
        logStatus: status,
        logMessage: msg,
        logOrigin: this.model,
        logComments: msg,
        loggedDateTme: new Date(),
      };

      LicenseAPI.addDeviceMatrix(payload)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    onCancel() {
      this.$router.go(-1);
    },

    onSupportClick() {
      let showSettings = false;
      this.$router.push({
        name: "Support",
        params: { showSettings },
      });
    },

    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },

    backPrompt() {
      if (this.email !== "") {
        if (!this.registerFlag) {
          this.popupMsg = "Entered data will be lost. Continue?";
          this.popup = true;
        }
      } else {
        this.$router.go(-1);
      }
    },

    closePrompt() {
      this.popup = false;
    },

    confirmPrompt() {
      this.popup = false;
      if (!this.registerFlag) this.$router.replace("/");
      else this.registerFlag = false;
    },
  },

  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style scoped lang="scss">
.posRel {
  position: relative;
}
.formList {
  position: relative;
  height: calc(100vh - 36vh);
  padding-bottom: 20px;
}
.formAbsAuto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.popupText {
  padding: 20px 20px;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.popUpBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #d2e4ff;
  width: 230px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btnRounded {
  background: #fff;
  border: 2px solid #fff;
  padding: 6px;
  border-radius: 50px;
  color: #0072ff;
  font-size: 16px;
}
.btnRoundedFull {
  font-size: 16px;
  background: #fff;
  border: 2px solid #fff;
  padding: 6px;
  border-radius: 50px;
  color: #0072ff;
  min-width: 90px;
  cursor: pointer;
}
.mainPage {
  height: calc(100vh - 50px);
}
.headerLogo {
  img {
    width: 230px;
  }
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}

.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
  font-size: 20px;
}
.btndef {
  background: #d5d5d5;
  border-radius: 8px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 8px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.error {
  color: #ff0000;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
.ml-1 {
  margin-left: 16px;
}
.internetOffline {
  position: fixed;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  background: #000;
  margin: auto;
  color: #fff;
  font-size: 14px;
  padding: 10px;
}
.offlineMsg {
  width: 300px;
  display: flex;
  align-items: center;
  margin: auto;
  text-align: left;
  line-height: 16px;
  img {
    height: 22px;
    opacity: 0.8;
  }
}

//Tabs Only
@media (min-width: 768px) {
  .contentPanel {
    padding-top: 5rem;
  }
  .info1 {
    margin: 4rem 0px;
  }
  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .info1 {
    margin: 1rem 0px;
  }
  .title1 {
    margin: 3% 0px;
  }
}

//Small mobile
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>
