var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainPage"},[_c('div',{staticClass:"mainPageContent"},[_c('div',{staticClass:"contentPanel"},[_vm._m(0),_c('div',{staticClass:"formList"},[_c('div',{staticClass:"formAbsAuto"},[_c('div',{staticClass:"formGroup"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"formControl",attrs:{"type":"email","required":""},domProps:{"value":(_vm.email)},on:{"blur":function($event){return _vm.validateEmail()},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(!_vm.validEmail)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.emailErr)+" ")]):_vm._e()]),_c('div',{staticClass:"formGroup"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.submittedLicKey),expression:"submittedLicKey"}],staticClass:"formControl",attrs:{"type":"text","required":""},domProps:{"value":(_vm.submittedLicKey)},on:{"input":function($event){if($event.target.composing)return;_vm.submittedLicKey=$event.target.value}}})]),(_vm.licKeyErr)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.licKeyErr)+" ")]):_vm._e(),_c('button',{class:[
              _vm.validEmail && _vm.onLine ? 'btnblue' : 'btndef',
              'txtUppercase',
              'mb-2',
            ],on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" SUBMIT")]),_c('br'),_c('div',{staticClass:"textCenter"},[_c('a',{staticClass:"backLink txtUppercase",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.backPrompt()}}},[_vm._v(" CANCEL ")])])])])]),_c('sc-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.onLine),expression:"!onLine"}],staticClass:"internetOffline"},[_vm._m(3)]),_c('div',{staticClass:"bottomFixed"},[_c('ul',{staticClass:"listInline"},[_c('li',{staticClass:"listItem"},[_c('a',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.onSupportClick()}}},[_vm._v(" SUPPORT ")])])])]),(_vm.popup)?_c('div',{staticClass:"popUp"},[_c('div',{staticClass:"popUpBox"},[_c('p',{staticClass:"popupText"},[_vm._v(_vm._s(_vm.popupMsg))]),_c('div',{staticClass:"modalFooter"},[(!_vm.registerFlag)?_c('button',{staticClass:"btnRounded",on:{"click":function($event){return _vm.closePrompt()}}},[_vm._v(" CANCEL ")]):_vm._e(),_vm._v("    "),_c('button',{staticClass:"btnRoundedFull",on:{"click":function($event){return _vm.confirmPrompt()}}},[_vm._v(" OK ")])])])]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headerLogo"},[_c('img',{attrs:{"src":require("../../assets/logo_b.png"),"alt":"Osmose"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Email Address "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("License Key "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offlineMsg"},[_c('img',{attrs:{"src":require("../../assets/disconnect.png"),"alt":"disconnect"}}),_c('div',{staticClass:"ml-1"},[_vm._v(" You are currently offline. please check your internet connection ")])])
}]

export { render, staticRenderFns }